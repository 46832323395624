import "./Tickets.css";
import { useNavigate } from "react-router-dom";

const Tickets = () => {
  const navigate = useNavigate();
  let tickets = [
    {
      location: "Karachi",
      type: "Get Early Access",
      event: "YOUTH ASSEMBLY ‘2025",
      what: "Early Bird Ticket",
      for: "1 PERSON",
      price: "Rs. 12,000",
      deadline: "1st Nov - 10th Dec 2024",
      bcode: "assets/icons/bcode1.png",
    },
  ];

  let ticketsvg = [
    {
      image: "assets/Tickets/ticket1.svg",
      form: "https://forms.gle/hb76o2D7PBKuSnaf7",
      start: "2024-10-1",
      deadline: "2024-12-10",
      dates: "1st Nov - 10th Dec 2024",
    },
    {
      image: "assets/Tickets/ticket2.svg",
      form: "https://forms.gle/hb76o2D7PBKuSnaf7",
      start: "2025-02-1",
      deadline: "2025-04-30",
      dates: "1st Feb - 30th Apr 2025",
    },
    {
      image: "assets/Tickets/ticket3.svg",
      form: "https://forms.gle/hb76o2D7PBKuSnaf7",
      start: "2025-05-1",
      deadline: "2025-05-31",
      dates: "1st May - 31st Mar 2025",
    },
  ];

  return (
    <div className=" bg-[#281EB3] border-[#281EB3] border-t">
      <div
        className="bg-white  text-center py-10 "
        style={{
          border: "0px",
          borderRadius: "30px 30px 0px 0px",
        }}
      >
        <div>
          <p className="location_subHead">PARTICIPATE NOW</p>
          <h3 className="location_mainHead  head_font">TICKETS</h3>
        </div>
        <div className="w-full  flex flex-col items-center py-10 gap-y-14">
          {/* <div
            id="ticket1"
            className=" min-h-max w-[70vw] rounded-xl border  block md:flex "
          >
            <div>
              <img src="assets/Tickets/ticket1.svg" />
            </div>
            <div className="w-full h-full  md:w-[65%] rounded-xl linear_bg p-2">
              <div className="flex h-full">
                <div className="center_flex w-[15%] rounded-[15px] md:block hidden   ">
                  <img
                    src="assets/icons/bcode1.png"
                    className="h-full w-full  rounded-md "
                  />
                </div>
                <div className="w-full md:px-3 px-3">
                  <div className="text-end  ">
                    <p className=" ticket_place_text">Karachi</p>
                  </div>
                  <div className="text-start ">
                    <p className=" ticket_type_text"> Get Early Access</p>
                  </div>
                  <div className="text-start ">
                    <p className=" event_name_text"> YOUTH ASSEMBLY ‘2025</p>
                  </div>
                  <div>
                    <table className="ticket-table">
                      <thead>
                        <tr>
                          <th>WHAT</th>
                          <th>FOR</th>
                          <th>PRICE</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Early Bird Ticket</td>
                          <td>1 PERSON</td>
                          <td>Rs. 12,000</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="md:w-[35%] w-full  p-3">
              <div className="flex flex-col justify-between  h-full">
                <div className=" text-right tick_deadline_text">
                  <p>1st Nov - 10th Dec 2024</p>
                </div>
                <div className="book_spot_box hover:cursor-pointer">
                  <div className="flex items-end">
                    <p className="book_spot_text">Book your spot</p>
                  </div>
                  <div>
                    <div className="bg-[#cdf195] rounded-[50%] p-2 flex justify-center items-center  ">
                      <img
                        className="w-[80%] h-[80%] object-contain "
                        src="assets/icons/arrow.png"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          {ticketsvg.map((ticket) => (
            <div
              id="ticket1"
              className="relative  min-h-max w-[70vw] rounded-xl border  block md:flex "
            >
              {(new Date() > new Date(ticket.deadline) ||
                new Date() < new Date(ticket.start)) && (
                <div
                  className="absolute h-full w-full bg-[rgba(0,0,0,0.6)] rounded-xl"
                  style={{ backdropFilter: "blur(2px)" }}
                ></div>
              )}
              <div>
                <img src={ticket.image} />
              </div>
              <div className="md:w-[35%] w-full  p-3">
                <div className="flex flex-col justify-between  h-full">
                  <div className=" text-right tick_deadline_text">
                    <p>{ticket.dates}</p>
                  </div>
                  <a
                    href={ticket.form}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="h-[35%]"
                  >
                    <div className="book_spot_box hover:cursor-pointer">
                      <div className="flex items-end">
                        <p className="book_spot_text">Book your spot</p>
                      </div>
                      <div>
                        <div className="bg-[#cdf195] rounded-[50%] p-2 flex justify-center items-center">
                          <img
                            className="w-[80%] h-[80%] object-contain"
                            src="assets/icons/arrow.png"
                          />
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Tickets;
