const YouthDetail = () => {
  return (
    <div
      className="mt-10 w-full   mb-0   flex flex-col items-center justify-center py-[25vh] image-background_globe ]"
      style={{ borderRadius: "30px 30px 0px 0px" }}
    >
      <div className=" flex w-[90%] ">
        <div className="text-left lg:w-[49%] w-full flex flex-col gap-4">
          <p className="location_subHead !text-[#D1F862]">What is it</p>
          <h3 className="location_mainHead !text-white  head_font">
            Empowering Pakistan's Youth through Quality Education
          </h3>
          <p className="about_detail !text-white">
            Team MJ recognizes the inadequacies in Pakistan's education system
            and strives to provide substantial, authentic, and impactful
            learning experiences for students. Our unique selling proposition
            lies in encouraging critical thinking, creativity, and innovative
            problem-solving skills. This enables our delegates to excel
            globally.
          </p>
        </div>
      </div>
    </div>
  );
};

export default YouthDetail;
