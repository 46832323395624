import { useNavigate } from "react-router-dom";
import { useState } from "react";
const Header = () => {
  const [expanded, setExpanded] = useState(false);
  const navigate = useNavigate();
  return (
    <div className="header">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          padding: "10px 0px 0px 0px",
        }}
      >
        <div className="logo">
          <img
            src={"assets/logo.png"}
            alt="logo"
            style={{ width: "90px", height: "60px" }}
          />
          <span className="logo_name !text-[#ffffff]">TEAM MJ</span>
        </div>
        <div className="headerTabsMobContainer space-x-3">
          <div className="register">
            <button
              onClick={() => {
                navigate("/register");
              }}
            >
              Register Now
            </button>
          </div>
          {/* <div className="register">
            <button
              onClick={() => {
                navigate("/YouthAssembly");
              }}
            >
              Youth Assembly
            </button>
          </div> */}
          {!expanded && (
            <div
              className="headerTabsMob"
              onClick={() => {
                setExpanded(!expanded);
              }}
            >
              <div className="bar1"></div>
              <div className="bar2"></div>
              <div className="bar3"></div>
            </div>
          )}
          {expanded && (
            <div
              className=""
              onClick={() => {
                setExpanded(!expanded);
              }}
            >
              <div className="bar1 -rotate-45 translate-y-[11px]"></div>
              <div className="bar2 opacity-0"></div>
              <div className="bar3 rotate-45 -translate-y-[11px]"></div>
            </div>
          )}
        </div>
        <div className="headerTabs">
          <div className="headerTabGroup">
            <button
              id="homeTab"
              className="headerTabText"
              onClick={() => {
                navigate("/");
              }}
            >
              <p>Home</p>
            </button>
            <button
              id="aboutUsTab"
              className="headerTabText"
              onClick={() => navigate("/about")}
            >
              <p>About Us</p>
            </button>
            <button
              id="eventsTab"
              className="headerTabText"
              onClick={() => {
                navigate("/events");
              }}
            >
              Events
            </button>
            <button
              id="eventsTab"
              className=" tabYouthText"
              onClick={() => {
                navigate("/YouthAssembly");
              }}
            >
              Youth Assembly
            </button>
          </div>
          <div className="register">
            <button
              onClick={() => {
                navigate("/register");
              }}
            >
              Register Now
            </button>
          </div>
        </div>
      </div>
      {expanded && (
        <div>
          <div>
            <button
              id="homeTab"
              className="headerTabText"
              onClick={() => {
                navigate("/");
              }}
            >
              Home
            </button>
          </div>
          <div>
            <button
              id="aboutUsTab"
              className="headerTabText"
              onClick={() => {
                navigate("/about");
              }}
            >
              About Us
            </button>
          </div>
          <div>
            <button
              className="headerTabText"
              onClick={() => {
                navigate("/events");
              }}
            >
              Events
            </button>
          </div>
          <button
            id="eventsTab"
            className="headerTabText tabYouthText"
            onClick={() => {
              navigate("/YouthAssembly");
            }}
          >
            Youth Assembly
          </button>
        </div>
      )}
    </div>
  );
};

export default Header;
