import "./Youth.css";
import YouthHero from "./Hero/Hero";
import YouthAboutUs from "./AboutUs/AboutUs";
import YouthLocation from "./Location/Location";
import YouthSpeaker from "./Speakers/Speakers";
import YouthPartners from "./Partners/Partners";
import YouthMentors from "./Mentors/Mentors";
import Footer from "./Footer/Footer";
import { useState, useRef } from "react";
import Tickets from "./Tickets/Tickets";
import YouthDetail from "./Detail/YouthDetail";
import WhyJoinUs from "./WhyJoinUs/WhyJoinUs";
import Achievements from "./Acheivements/Achievements";

const Youth = () => {
  const [expanded, setExpanded] = useState(false);
  const targetRef = useRef(null);
  const speakerRef = useRef(null);
  const partnerRef = useRef(null);
  const ticketRef = useRef(null);

  const scrollToComponent = (ref) => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <>
      <div className="w-full h-[100vh] p-3">
        {/* <YouthHero /> */}
        <div className=" image-background rounded-[15px]  relative  h-[95%]">
          <div className=" p-5 flex justify-between">
            <a href="/YouthAssembly">
              <img
                alt="Youth Assembly logo"
                src=" assets/YAssem/logo.png"
                className="  h-10 w-32"
              ></img>
            </a>
            <div className="nav_button_container  gap-6 ">
              <a href="/">
                <p className="nav-text  !font-bold uppercase">Team MJ</p>
              </a>
              <a href="#head">
                <p
                  className="nav-text"
                  onClick={() => {
                    scrollToComponent(targetRef);
                  }}
                >
                  About Us
                </p>
              </a>
              {/* <p
                className="nav-text"
                onClick={() => {
                  scrollToComponent(speakerRef);
                }}
              >
                Our Speakers
              </p> */}
              <a href="/YouthAssembly/Partner">
                <p
                  className="nav-text"
                  onClick={() => {
                    scrollToComponent(partnerRef);
                  }}
                >
                  Partner With us
                </p>
              </a>
              <button
                onClick={() => {
                  scrollToComponent(ticketRef);
                }}
                className="nav-button"
              >
                Get Tickets
              </button>
            </div>
            <div
              className="nav_button_container_res"
              onClick={() => {
                setExpanded(!expanded);
              }}
            >
              <div className="bar1_y"></div>
              <div className="bar2_y"></div>
              <div className="bar3_y"></div>
            </div>

            {expanded && (
              <div className="absolute top-[12%] space-y-4">
                <a href="#head">
                  <p
                    className="nav-text"
                    onClick={scrollToComponent(targetRef)}
                  >
                    About Us
                  </p>
                </a>
                {/* <p
                  className="nav-text"
                  onClick={() => {
                    scrollToComponent(speakerRef);
                  }}
                >
                  Our Speakers
                </p> */}
                <p
                  className="nav-text"
                  onClick={() => {
                    scrollToComponent(partnerRef);
                  }}
                >
                  Partner With us
                </p>
                <button
                  onClick={() => {
                    scrollToComponent(ticketRef);
                  }}
                  className="nav-button"
                >
                  Get Tickets
                </button>
              </div>
            )}
          </div>
          <div className="h-[80%] flex justify-center items-center">
            <div className="text-center">
              <h3 className="hero_mainText">Global Perspectives, </h3>
              <h3 className="hero_mainText2">Youth-Led Solutions</h3>
              <p className="hero_sub">
                Step into the role of world leaders and create impactful change
                through diplomacy and dialogue
              </p>
            </div>
          </div>
        </div>
        <div ref={targetRef}>
          <YouthAboutUs />
        </div>
        <div>
          <YouthDetail />
        </div>
        <div>
          <WhyJoinUs />
        </div>
        <div>
          <Achievements />
        </div>
        <YouthLocation />
        {/* <div ref={speakerRef}>
          <YouthSpeaker />
        </div> */}
        {/* <div ref={partnerRef}>
          <YouthPartners />
        </div> */}
        {/* <YouthMentors /> */}
        <div ref={ticketRef}>
          <Tickets />
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Youth;
