import { useState, useRef } from "react";
import Footer from "../YAssem/Footer/Footer";
import PartnerHero from "./Components/PartnerHero";
import Sponsorships from "./Components/Sponsorships";
import PartnerForm from "./Components/PartnerForm";
const YouthPartner = () => {
  const [expanded, setExpanded] = useState(false);
  const targetRef = useRef(null);
  const speakerRef = useRef(null);
  const partnerRef = useRef(null);
  const ticketRef = useRef(null);

  const scrollToComponent = (ref) => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <div className="w-full h-[100vh] p-3">
      {/* <YouthHero /> */}
      <div className="  rounded-[15px]  relative  h-[10%]">
        <div className=" p-5 flex justify-between">
          <a href="/YouthAssembly">
            <img
              alt="Youth Assembly logo"
              src="/assets/YAssem/themed_logo.svg"
              className="  h-10 w-32"
            ></img>
          </a>
          <div className="nav_button_container  gap-6 ">
            <a href="/">
              <p className="nav-text !text-[#7a161e] !font-bold uppercase">
                Team MJ
              </p>
            </a>
            {/* <a href="#head">
              <p
                className="nav-text !text-[#303030]"
                onClick={() => {
                  scrollToComponent(targetRef);
                }}
              >
                About Us
              </p>
            </a> */}
            {/* <p
                className="nav-text"
                onClick={() => {
                  scrollToComponent(speakerRef);
                }}
              >
                Our Speakers
              </p> */}

            <button
              onClick={() => {
                scrollToComponent(ticketRef);
              }}
              className="nav-button"
            >
              Get Tickets
            </button>
          </div>

          <div
            className="nav_button_container_res"
            onClick={() => {
              setExpanded(!expanded);
            }}
          >
            <div className="bar1_y !bg-black"></div>
            <div className="bar2_y !bg-black"></div>
            <div className="bar3_y !bg-black"></div>
          </div>
        </div>
      </div>
      {expanded && (
        <div className="  py-4 px-2   flex flex-col gap-5">
          {/* <a href="#head">
              <p className="nav-text uppercase">Team MJ</p>
            </a> */}
          <a href="/">
            <p className="nav-text !text-[#7a161e] !font-bold uppercase">
              Team MJ
            </p>
          </a>
          {/* <a href="#head">
            <p
              className="nav-text !text-[#303030]"
              onClick={scrollToComponent(targetRef)}
            >
              About Us
            </p>
          </a> */}

          <a className=" nav-text !text-[#2500ff]">Get Tickets</a>
        </div>
      )}
      <div>
        <PartnerHero />
      </div>
      <div>
        <Sponsorships />
      </div>
      <div>
        <PartnerForm />
      </div>
      <Footer />
    </div>
  );
};

export default YouthPartner;
