// src/pages/Events.js
import React from "react";
import "../styles/Events.css"; // Ensure you have created the Events.css file
import EventCard from "../components/EventCard"; // Make sure EventCard is imported
import Header from "../components/Headers/Header";

const Events = () => {
  const eventsData = [
    // {
    //   location: "Washington MUN",
    //   date: "6th - 12th November, 2024",
    //   country: "USA",
    //   deadline: " 15th September",
    //   imgSrc: "/assets/washington.webp",
    //   acceptingApplications: true,
    //   status: 0,
    // },

    {
      location: "Yale MUN",
      date: "3rd week of January",
      imgSrc: "/assets/event_6.jpg",
      deadline: " 30th September",
      acceptingApplications: true,
      status: 0,
    },
    {
      location: "Oxford MUN",
      date: "7th – 9th Fbruary, 2024",
      country: "London",
      imgSrc: "/assets/event_6.jpg",
      acceptingApplications: true,
      status: 0,
    },

    {
      location: "Harvard MUN",
      date: "3rd week of February",
      imgSrc: "/assets/harvard.webp",
      acceptingApplications: true,
      status: 0,
    },
  ];

  return (
    <div className="events-container">
      <Header />
      <div class="IntroSection">
        <img
          class="half-image-new"
          src="../assets/event_bg.png"
          alt="All Events"
        />
      </div>
      {/* <div className="events-list"> */}
      <div className="event_collection ">
        {eventsData.map((event, index) => (
          <EventCard
            key={index}
            location={event.location}
            date={event.date}
            country={event.country}
            imgSrc={event.imgSrc}
            acceptingApplications={event.acceptingApplications}
            deadline={event.deadline}
          />
        ))}
      </div>
    </div>
  );
};

export default Events;
