import "./AboutUs.css";
const YouthAboutUs = () => {
  return (
    <div className="relative mx-[5%] ">
      <img
        alt="comma1"
        src="assets/YAssem/comma1.png"
        className="absolute -z-[9999] -top-[100px] comma_img "
      />
      <div className="  w-full flex flex-col justify-center items-center py-[170px] gap-4">
        <p className="about_subHead">What is it</p>
        <h3 className="about_mainHead text-center ">THE FIRST OF ITS KIND</h3>
        <p className="about_detail w-full md:w-[40%]  text-center">
          The Youth Assembly is an International-grade Conference to be hosted
          by Team MJ to grant exposure to delegates from all around the world to
          take a feast of diplomacy at its finest.
        </p>
      </div>
      <img
        alt="comma2"
        src="assets/YAssem/comma2.png"
        className="absolute right-0 top-[350px]  -z-[9999] comma_img "
      />
    </div>
  );
};

export default YouthAboutUs;
