import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./pages/Home/Home";
import AboutUs from "./pages/AboutUs";
import Events from "./pages/Events";
import Register from "./pages/Register";
import "./App.css"; // Ensure this CSS includes the global styles
import Youth from "./pages/YAssem/Youth";
import "leaflet/dist/leaflet.css";
import YouthPartner from "./pages/PartnerWithUs/YouthPartner";

function App() {
  return (
    <Router>
      <div className="App">
        {/* Include the Header at the top */}
        {/* <Header /> */}

        {/* Main content area */}
        <main>
          <Routes>
            {/* Route for Home page */}
            <Route path="/" element={<Home />} />

            {/* Route for About Us page */}
            <Route path="/about" element={<AboutUs />} />

            {/* Route for Events page */}
            <Route path="/events" element={<Events />} />

            {/* Route for Register page */}
            <Route path="/register" element={<Register />} />
            <Route path="/youthAssembly" element={<Youth />} />
            <Route path="/youthAssembly/Partner" element={<YouthPartner />} />
          </Routes>
        </main>

        {/* Include the Footer at the bottom */}
        {/* <Footer /> */}
      </div>
    </Router>
  );
}

export default App;
