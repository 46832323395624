import React from "react";
import "../styles/aboutUsStyle.css"; // Make sure to create AboutUs.css for styling
import Header from "../components/Headers/Header";

const AboutUs = () => {
  return (
    // <div className="about-container">
    //   <div className="header-section">
    //     <div className="logo">
    //       <a href="/">
    //         <img src="/assets/logo.png" alt="logo" />
    //       </a>
    //       <span className="logo_name">TEAM MJ</span>
    //     </div>
    //   </div>

    //   <div className="sec1_container">
    //     <div className="IntroSection">
    //       <img
    //         className="half-image-new"
    //         src="/assets/Frame100.png"
    //         alt="About Us"
    //       />
    //     </div>
    //     <div className="section2">
    //       <div className="sec2Detail">
    //         <div className="heading">
    //           <p>From an early age, I've been driven...</p>
    //         </div>
    //         <div className="detailText">
    //           <p>
    //             by the belief that education holds the key to shaping not just
    //             individual futures but entire societies...
    //           </p>
    //           <p>
    //             In my journey, I've sought to decolonize our education system,
    //             challenging conventional paradigms and encouraging students to
    //             think critically...
    //           </p>
    //         </div>
    //       </div>
    //       <div className="sec2Image">
    //         <img src="/assets/aboutus_1.png" alt="About Us" />
    //       </div>
    //     </div>

    //     <div className="section3">
    //       <div className="section3Detail">
    //         <p>
    //           In a world where the specter of AI looms large, I see education as
    //           the ultimate safeguard...
    //         </p>
    //       </div>
    //       <div className="section3Image">
    //         <img src="/assets/ploygon2.png" alt="Polygon Design" />
    //       </div>
    //     </div>

    //     <div className="section4">
    //       <div id="whyItWorks" className="whyItWorks">
    //         <p>Why it works</p>
    //         <img src="/assets/WaveDesign.png" alt="Wave Design" />
    //       </div>

    //       <div className="wiw_container">
    //         <div className="wiw_sub">
    //           <img src="/assets/aboutus_3.png" alt="Personalized Learning" />
    //           <h3>Personalized Learning</h3>
    //           <p>
    //             Sessions are tailored to bring out the best in each student. No
    //             one is left behind.
    //           </p>
    //         </div>
    //         <div className="wiw_sub">
    //           <img src="/assets/aboutus_4.png" alt="Trusted Content" />
    //           <h3>Trusted Content</h3>
    //           <p>Created by an expert, learning is always a priority.</p>
    //         </div>
    //         <div className="wiw_sub">
    //           <img src="/assets/aboutus_5.png" alt="Tools to Empower" />
    //           <h3>Tools to Empower</h3>
    //           <p>
    //             TEAM MJ can identify gaps in their students’ understanding and
    //             meet the needs of every student.
    //           </p>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
    <div style={{ position: "relative" }}>
      <Header />
      <div className="sec1_container">
        <div className="IntroSection">
          <img
            className="half-image-new"
            src="../assets/Frame100.png"
            alt="About Us"
          />
        </div>
        <div className="section2">
          <div data-aos="fade-right" className="sec2Detail">
            <div className="heading">
              <p>From an early age, I've been driven...</p>
            </div>
            <div class="detailText">
              <p>
                by the belief that education holds the key to shaping not just
                individual futures but entire societies. Yet, amidst the vast
                landscape of subjects, I found myself drawn to a singular
                mission: to catalyze tangible change in students' thinking
                patterns.
              </p>
              <p>
                In my journey, I've sought to decolonize our education system,
                challenging conventional paradigms and encouraging students to
                think critically. It's not about imparting knowledge; it's about
                fostering a culture of inquiry where students become architects
                of their own understanding.
              </p>
              <p>
                I've always believed that true success lies in the
                transformation of minds. When students engage deeply, when their
                ideas are presented on global platforms like the UN and emerge
                victorious, it's more than just a win—it's a testament to the
                power of education to shape ideologies and inspire leadership.
              </p>
            </div>
          </div>
          <div
            data-aos="fade-left"
            style={{ width: "100%", textAlign: "center" }}
          >
            <img
              width="400px"
              height="450px"
              src="../assets/aboutus_1.png"
              alt="About Us"
            />
          </div>
        </div>
      </div>
      <div>
        <div data-aos="fade-up" className="section3">
          <img
            src="../assets/ploygon2.png"
            style={{ objectFit: "cover" }}
            alt="Polygon"
          />
          <div class="detailText">
            <p>
              In a world where the specter of AI looms large, I see education as
              the ultimate safeguard, empowering students to confront complex
              challenges and innovate solutions. Because in the end, it's not
              just about what students learn; it's about what they create, what
              they imagine, and how they lead.
            </p>
            <p>
              My journey in teaching MUN isn't just about preparing students for
              conferences; it's about igniting a spark within them, fueling
              their passion for knowledge and their drive to make a difference.
              And in their journey, I too continue to learn, to evolve, and to
              believe in the transformative power of education.
            </p>
          </div>
        </div>
      </div>
      <div className="section4">
        <div
          data-aos="fade-down"
          id="whyItWorks"
          style={{ textAlign: "center" }}
        >
          <p>Why it works</p>
          <div>
            <img src="../assets/WaveDesign.png" alt="Wave Design" />
          </div>
        </div>
        <div data-aos="fade-up" className="wiw_container">
          <div className="wiw_sub">
            <img
              width="290"
              height="290"
              src="../assets/aboutus_3.png"
              alt="Personalized Learning"
            />
            <div style={{ textAlign: "center" }}>
              <h3>Personalized Learning</h3>
              <p>
                Sessions are tailored to bring out the best in each student...
              </p>
            </div>
          </div>
          <div className="wiw_sub" style={{ paddingBottom: "22px" }}>
            <img
              width="290"
              height="290"
              src="../assets/aboutus_4.png"
              alt="Trusted Content"
            />
            <div style={{ textAlign: "center" }}>
              <h3>Trusted Content</h3>
              <p>Created by an expert, learning is always a priority.</p>
            </div>
          </div>
          <div className="wiw_sub" style={{ paddingTop: "20px" }}>
            <img
              width="290"
              height="290"
              src="../assets/aboutus_5.png"
              alt="Tools to Empower"
            />
            <div style={{ textAlign: "center" }}>
              <h3>Tools to Empower</h3>
              <p>
                TEAMMJ can identify gaps in students’ understanding, tailor
                instruction, and meet the needs of every student.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="blogsec">
        <p className="blog">BLOGS</p>

        <div id="recent" className="blogdisplayyy">
          <div data-aos="fade-right" className="blogs">
            <img
              className="blogimg"
              src="../assets/aboutus_6.png"
              alt="Blog Image 1"
            />
            <div class="blogcontent">
              <p class="bloghead1">
                Pioneering Change- Murtaza Jafry’s MUN Initiative in Pakistan
                Redefines Education Across the Nation
              </p>
              <p class="blogpara1">
                A decade ago, Murtaza Jafry embarked on a personal research
                journey to understand the shortcomings of Pakistan’s education
                system. He sought to uncover why Pakistani students were not
                excelling at international levels, despite their potential.
                 Through his research, he identified some major areas that are
                vastly unexplored but greatly essential as life skills: critical
                thinking, research, and communication skills. This discovery
                ignited a drive within him to revolutionise the education
                landscape in Pakistan. The journey of understanding how to
                break...
              </p>
              <a
                target="_blank"
                href="https://startuppakistan.com.pk/pioneering-change-murtaza-jafrys-mun-initiative-in-pakistan-redefines-education-across-the-nation/"
              >
                <button id="about_readmore">Read More</button>
              </a>
            </div>
          </div>
          <div data-aos="fade-right" data-aos-duration="1500" className="blogs">
            <img
              className="blogimg"
              src="../assets/aboutus_7.png"
              alt="Blog Image 2"
            />
            <div class="blogcontent">
              <p class="bloghead1">
                Pakistan's Team Mj Wins Laurels at Cambridge Model United
                Nations 2024
              </p>
              <p class="blogpara1">
                What started as a casual introduction unfolded into a
                transformative experience with Team MJ, which Shahvaiz describes
                as less of a MUN class and more of a close-knit family driven by
                a shared passion for debating and winning. The unique atmosphere
                within Team MJ, characterized by camaraderie and a collective
                passion for debate, fostered an environment where Shahvaiz not
                only honed his debating skills but also grew as an individual.
              </p>

              <a
                target="_blank"
                href="https://wow360.pk/pakistans-team-mj-wins-laurels-at-cambridge-model-united-nations-2024/"
              >
                <button id="about_readmore">Read More</button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
