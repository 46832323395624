import "./Location.css";
import Map from "./Map";
import { Carousel } from "@kkx64/react-simple-carousel";
const YouthLocation = () => {
  let locations = [
    {
      name: "Karachi",
      date: "June 2025",
      image: "assets/YAssem/karachi.png",
      dates: "20th-22nd June 2025",
      timing: "10 AM - 6 PM",
      venue:
        "Mövenpick, Club Rd, Civil Lines Karachi, Karachi City, Sindh 75530",
      map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3276.3629532088494!2d67.02363177482616!3d24.846800445804938!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3eb33ddf1f6ef4dd%3A0xf53ae106a1d4c831!2sM%C3%B6venpick%20Karachi!5e1!3m2!1sen!2s!4v1730822636968!5m2!1sen!2s&iwloc=near",
      tba: 0,
    },
    {
      name: "Dubai",
      date: "TBA",
      image: "assets/YAssem/Dubai.png",
      dates: "20th-22nd June 2025",
      timing: "10 AM - 6 PM",
      venue:
        "Club Rd, Civil Lines Karachi, Karachi City, Sindh 75530 Timings	10 AM - 6 PM",
      map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3276.3629532088494!2d67.02363177482616!3d24.846800445804938!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3eb33ddf1f6ef4dd%3A0xf53ae106a1d4c831!2sM%C3%B6venpick%20Karachi!5e1!3m2!1sen!2s!4v1730822636968!5m2!1sen!2s&iwloc=near",
      tba: 1,
      placeImage:
        "https://www.shutterstock.com/image-photo/dubai-uae-26-may-2024-600nw-2476113149.jpg",
    },
    {
      name: "US",
      date: "TBA",
      image: "assets/YAssem/Us.png",
      dates: "20th-22nd June 2025",
      timing: "10 AM - 6 PM",
      venue:
        "Club Rd, Civil Lines Karachi, Karachi City, Sindh 75530 Timings	10 AM - 6 PM",
      map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3276.3629532088494!2d67.02363177482616!3d24.846800445804938!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3eb33ddf1f6ef4dd%3A0xf53ae106a1d4c831!2sM%C3%B6venpick%20Karachi!5e1!3m2!1sen!2s!4v1730822636968!5m2!1sen!2s&iwloc=near",
      tba: 1,
      placeImage:
        "https://media.istockphoto.com/id/1348089637/photo/san-francisco-skyline.jpg?s=612x612&w=0&k=20&c=WsolfyTvYhqdAYwVA5X2tdJmEzTgAzBBzv-f9EgskD8=",
    },
  ];
  return (
    <div className="text-center">
      <div>
        <p className="location_subHead">Stay tuned</p>
        <h3 className="location_mainHead  head_font">OUR LOCATIONS</h3>
      </div>
      <div className="flex flex-col  gap-8 justify-center items-center pt-10  ">
        <div
          className=" flex  md:flex-row flex-col items-center md:gap-0 gap-6 w-[70%] justify-between  
        
        "
        >
          {locations.map((loc) => (
            // <div className="relative ">
            <div className={`location_image relative text-left`}>
              <img
                alt="city"
                className={`location_image transition-all duration-300  ${"hover:brightness-[40%]"}`}
                src={loc.image}
              />
              <div className="absolute bottom-0   w-full pl-5 py-3">
                <div>
                  <h4 className="img_headText">{loc.name}</h4>
                </div>
                <div>
                  <p className="img_subText">{loc.date}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div
          className="w-full   flex flex-col items-center justify-center py-16 bg-[#281EB3]"
          style={{ borderRadius: "30px 30px 0px 0px" }}
        >
          <Carousel>
            {locations.map((location, index) =>
              !location.tba ? (
                <div className="  flex my-10 md:flex-row flex-col w-[70%] justify-between">
                  <div className="flex justify-center items-center">
                    <div className="text-start space-y-4   ">
                      <div className="flex flex-col md:flex-row  md:items-center  items-start gap-3 space-x-10">
                        <h3 className="location_head head_font uppercase text-start">
                          {location.name}
                        </h3>
                        <h4 className="location_date text-start !m-0">
                          {location.dates}
                        </h4>
                      </div>
                      <div>
                        <table>
                          <tr>
                            <td className="table_cell table_row_head">Venue</td>
                            <td className="table_cell table_row_detail">
                              {location.venue}
                            </td>
                          </tr>
                          <tr>
                            <td className="table_cell table_row_head">
                              Timings
                            </td>
                            <td className="table_cell table_row_detail">
                              {location.timing}
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="space-x-3 py-8 ">
                        <a
                          href="https://forms.gle/hb76o2D7PBKuSnaf7"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <button className="ticket-button">
                            Get Early Bird Tickets
                          </button>
                        </a>
                        <a className="partner_link"> Partner With Us</a>
                      </div>
                    </div>
                  </div>
                  <iframe
                    className="rounded-md h-[250px] md:w-[40%] w-[100%]"
                    src={location.map} // style="border:0;"
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                  ></iframe>
                  {/* <div>Map</div> */}
                </div>
              ) : (
                <div className="  w-full h-[60vh]  relative flex justify-center items-center">
                  <img
                    className="absolute opacity-70 object-cover brightness-50 rounded-md h-[70%] w-[50%]"
                    src={location.placeImage}
                  ></img>
                  <div className="  relative flex my-10 md:flex-row flex-col  items-center justify-between">
                    <div className="flex justify-center items-center left-0">
                      <div className="text-start space-y-4   ">
                        <div className=" flex flex-col md:items-center  items-start gap-3 ">
                          <h3 className="location_head head_font  uppercase text-start">
                            {location.name}
                          </h3>
                          <h3 className="location_head head_font     uppercase text-start !text-white">
                            Coming Soon!
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div>Map</div> */}
                </div>
              )
            )}
          </Carousel>
          <div>
            <p className="location_subHead !text-[#D1F862]">Stay tuned</p>
            <h3 className="location_mainHead !text-white  head_font">
              UPDATES COMING SOON
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default YouthLocation;
