import { useNavigate } from "react-router-dom";

const RegisterHeader = () => {
  const navigate = useNavigate();
  return (
    <div>
      <div className=" flex items-center ">
        <img
          onClick={() => navigate("/")}
          className="object-cover !w-[100px] !h-[60px]"
          src="/assets/logo.png"
          alt="logo"
        />
        <span class="logo_name text-[#3f0d0e]">TEAM MJ</span>
      </div>
    </div>
  );
};

export default RegisterHeader;
