import "./WhyJoinUs.css";
const WhyJoinUs = () => {
  return (
    <div>
      <div
        className="w-full   flex flex-col items-center justify-center py-16 bg-white "
        style={{ borderRadius: "30px 30px 0px 0px" }}
      >
        <div>
          <p className="location_subHead text-center !text-[#281EB3]">
            Why join us
          </p>
          <h3 className="location_mainHead !text-black  head_font">
            BENEFITS TO THE DELEGATES
          </h3>
        </div>
        <div className="flex lg:flex-row flex-col my-10 justify-center items-center w-[90%] gap-10">
          <div className="wiw_sub">
            <img
              className="joinUs_pics"
              src="assets/YAssem/joinUs/image3.svg"
              alt="Personalized Learning"
            />
            <div style={{ textAlign: "center" }}>
              <h3>REACHING NEW HEIGHTS</h3>
              <p className="joinUs_p">
                Cultivate delegates who can serve as ambassadors of Pakistan on
                international stage
              </p>
            </div>
          </div>
          <div className="wiw_sub">
            <img
              className="joinUs_pics"
              src="assets/YAssem/joinUs/image2.svg"
              alt="Trusted Content"
            />
            <div style={{ textAlign: "center" }}>
              <h3>BECOMING BETTER</h3>
              <p className="joinUs_p">
                Encourages critical thinking, creativity, and effective
                communication skills
              </p>
            </div>
          </div>
          <div className="wiw_sub">
            <img
              className="joinUs_pics"
              src="assets/YAssem/joinUs/image1.svg"
              alt="Tools to Empower"
            />
            <div style={{ textAlign: "center" }}>
              <h3>DEVELOPING CONFIDENCE</h3>
              <p className="joinUs_p">
                Provide a platform for youth to engage with global issues and
                develop innovative solutions
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyJoinUs;
