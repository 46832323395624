const Sponsorships = () => {
  return (
    <div>
      <div className="w-full text-center">
        <p className="location_subHead">OUR SPONSORSHIP TIERS</p>
        <h3 className="location_mainHead  head_font">CHOOSE YOUR IMPACT</h3>
      </div>
      <div className="flex justify-center items-center gap-8 my-10 md:flex-row flex-col">
        <img src="/assets/YAssem/silver.svg" />
        <img src="/assets/YAssem/platinum.svg" />
        <img src="/assets/YAssem/silver.svg" />
      </div>
    </div>
  );
};

export default Sponsorships;
