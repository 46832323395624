import "./Footer.css";
const Footer = () => {
  return (
    <div className="my-10 mt-[25vh]">
      <div className="relative">
        <div className="footer_bg  ">
          <div className="  justify-end   w-full flex flex-col ">
            <div className="space-y-1 pt-16 pl-10">
              <p className="text-white  head_font">Get in Touch</p>
              <p className="text-white getInTouchText  font-semibold">
                +923002773182
              </p>
              <p className="text-white font-semibold">www.teammj.co</p>
              <p className="text-white font-semibold">info@teammj.co</p>
            </div>
            <div className=" w-full  flex p-10 justify-between pl-6">
              <img
                alt="Youth Assembly logo"
                src="/assets/YAssem/logo.png"
                className="  h-10 w-32"
              ></img>
              <div className="space-y-2">
                <div className="flex items-center space-x-6 ">
                  <p className="text-white">Follow Us</p>
                  <a
                    href="https://www.facebook.com/share/17bLmhikLj/?mibextid=LQQJ4d"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      alt="Youth Assembly logo"
                      src="/assets/YAssem/fb.png"
                      className="  h-4 w-4  object-contain"
                    ></img>
                  </a>
                  <a
                    href="https://www.instagram.com/youthassemblymun/profilecard/?igsh=MTN4c201anp1ZHd0bw=="
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      alt="Youth Assembly logo"
                      src="/assets/YAssem/insta.png"
                      className="  h-4 w-4 object-contain"
                    ></img>
                  </a>
                  <a
                    href="https://wa.me/+923002773182"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      alt="Youth Assembly logo"
                      src="/assets/YAssem/wa.png"
                      className="  h-4 w-4  object-contain"
                    ></img>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="absolute footer_text   text-center -top-[40%] w-full">
          <h3>Are you ready?</h3>
        </div>
      </div>
    </div>
  );
};

export default Footer;
