// src/components/EventCard.js
import React from "react";
import { Link } from "react-router-dom";
import "../styles/EventCard.css"; // Make sure to create this CSS file

const EventCard = ({
  location,
  date,
  country,
  imgSrc,
  acceptingApplications,
  deadline,
}) => {
  return (
    <div className="event-card">
      <img className="event-img" src={imgSrc} alt={`${location}`} />
      <div className="event-details">
        <div className="lochead">
          <p className="location">{location}</p>
          {acceptingApplications && (
            <div className="highlight">
              <p>Accepting Applications</p>
            </div>
          )}
        </div>
        <div className="font-semibold text-sm">
          <p className="event-date">
            {date} <em>{country}</em>
          </p>
          <div className="flex gap-2">
            <p>Deadline:</p>
            <p>{deadline ? deadline : "TBA"}</p>
          </div>
        </div>
        <div className="register-button">
          <Link to="/register">Register Now</Link>
        </div>
      </div>
    </div>
  );
};

export default EventCard;
