import "./achievements.css";

const PinIcon = ({ fillColor = "#3B0DBA", size = 38, opacity = 0.6 }) => {
  return (
    <svg
      width={size}
      height={(size * 49) / 38} // Maintain aspect ratio based on width
      viewBox="0 0 38 49"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity={opacity}>
        <path
          d="M19.1665 48.1176C18.994 48.1176 18.2618 48.0856 17.51 47.5803C10.4944 42.8596 4.62745 34.6879 1.81479 25.7248C-0.25835 19.1159 0.500884 12.5415 3.89777 7.68532C7.14182 3.0485 12.4861 0.221048 18.1928 0.122444C24.4664 0.0213759 30.6193 3.21859 34.2257 8.48401C42.2397 20.1833 33.146 36.0609 25.0778 43.8802C24.3284 44.6074 23.5297 45.3321 22.7039 46.0371C22.1493 46.5104 21.5798 46.9739 21.0079 47.42C20.7885 47.5901 20.1205 48.1103 19.1813 48.1201C19.1764 48.1176 19.1715 48.1176 19.1665 48.1176ZM18.6094 45.9434C18.8929 46.1333 19.1764 46.1456 19.1788 46.1456C19.4327 46.1431 19.6866 45.9459 19.7951 45.8596C20.3424 45.4332 20.8921 44.987 21.4221 44.5334C22.2158 43.8555 22.9825 43.1579 23.7023 42.4603C28.1444 38.1538 31.9381 32.3362 33.8461 26.8982C35.4854 22.2293 36.5971 15.438 32.5963 9.59576C29.3596 4.8702 23.86 1.99837 18.2224 2.09204C13.1443 2.17832 8.39163 4.69271 5.50748 8.81433C2.4631 13.1652 1.80246 19.1135 3.69071 25.1332C6.37766 33.6846 11.9561 41.4644 18.6094 45.9434Z"
          fill={fillColor}
        />
        <path
          d="M18.9911 29.3826C12.9418 29.3826 8.02148 24.4623 8.02148 18.413C8.02148 12.3637 12.9418 7.44336 18.9911 7.44336C25.0404 7.44336 29.9607 12.3637 29.9607 18.413C29.9607 24.4623 25.038 29.3826 18.9911 29.3826ZM18.9911 9.41543C14.0289 9.41543 9.99355 13.4508 9.99355 18.413C9.99355 23.3752 14.0289 27.4105 18.9911 27.4105C23.9533 27.4105 27.9887 23.3752 27.9887 18.413C27.9887 13.4508 23.9509 9.41543 18.9911 9.41543Z"
          fill={fillColor}
        />
      </g>
    </svg>
  );
};

const Achievements = () => {
  let achievementList = [
    {
      color: "#3B0DBA",
      location: "UNHQ",
      date: "April 2024",
      title: "Best Delegate",
    },
    {
      color: "#106360",
      location: "Cambridge",
      date: "February 2024",
      title: "Best Delegate",
    },
    {
      color: "#49D4EC",
      location: "Yale",
      date: "January 2024",
      title: "Best Delegate, Honorable Mention",
    },
    {
      color: "#BCEC71",
      location: "GENEVA",
      date: "June 2023",
      title: "Best Delegate, Outstanding Delegate",
    },
  ];

  return (
    <div>
      <div
        className="w-full my-20  flex flex-col items-center justify-center py-16 bg-white "
        style={{ borderRadius: "30px 30px 0px 0px" }}
      >
        <div>
          <p className="location_subHead text-center !text-[#281EB3]">
            We do better
          </p>
          <h3 className="location_mainHead !text-black  head_font">
            OUR PAST ACHEIVEMENTS
          </h3>
        </div>
        <div className="flex lg:flex-row flex-col gap-10  w-[90%] justify-between my-16">
          {achievementList.map((achievement) => (
            <div className="flex items-center justify-center flex-col gap-2">
              <PinIcon fillColor={achievement.color} />
              <div className="flex gap-3 items-center">
                <h3 className="ach_head">{achievement.location}</h3>
                <p className="ach_date">{achievement.date}</p>
              </div>
              <div>
                <p className="ach_title">{achievement.title}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Achievements;
