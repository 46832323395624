import "../partner.css";
const PartnerHero = () => {
  return (
    <div className="flex justify-center">
      <div className="flex lg:flex-row flex-col-reverse w-[90%] justify-between lg:py-20  pb-20  py-0 items-center">
        <div className="lg:w-[55%] w-full">
          <h1 className="fSans partner_hero_head">
            Foster
            <span className="partner__hero_subHead ml-5">
              <em>future leaders</em>
            </span>
          </h1>
          <p className="fSans partner_hero_p ">
            Model United Nations (MUN) events unite passionate youth to debate
            global issues, foster diplomacy, and build leadership skills. By
            sponsoring our MUN, your brand gains visibility among future leaders
            while supporting education, innovation, and global impact. Partner
            with us to inspire change and connect with tomorrow’s changemakers.
          </p>
        </div>
        <div className=" flex justify-center  lg:w-[40%] w-full ">
          <img className="partner_hero_img" src="/assets/YAssem/Group 67.png" />
        </div>
      </div>
    </div>
  );
};

export default PartnerHero;
