import React, { useState, useEffect } from "react";
import { KJUR } from "jsrsasign";

const PartnerForm = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    contact: "",
    email: "",
    company: "",
    type: "",
  });

  const { firstName, lastName, contact, email, company, type } = formData;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const SERVICE_ACCOUNT_EMAIL =
    "mj-website@mj-website-427713.iam.gserviceaccount.com";
  const PRIVATE_KEY = `-----BEGIN PRIVATE KEY-----
\nMIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQCwAhPxtpiTMrFg\n1p+pLOXqVLsSVtp/Ix2s6c6IotIsd/HDPulquduQqKT6TLuTC2z6rvniHBQeUhQ7\nH+jXLoiT1N6fwzpP39qQfkM6eoY9vGpewrROisUfwPD6/b8VU/bxMq8AWdDVOtle\nFDK/GCzVcgvxDOSYl9En/1sOAPkcyzovbeo25yVgnnngvP3Y8EgVW7G2atLyqU0f\nSUWNeVSZ1Y5IcVALt3dfHC926DkQOVkJfsQbVHmd72Oq20GzTLWE2zxi5Xv31qZJ\nmckALmEO+x3iDlNh9MqX3QzXiDdrauQId6fF+fisORi8E0q/nqKKq/8nCAl0sGuh\nKJmQsz5RAgMBAAECggEAMGr56pH1CfU1h3KzLDpOKzbQ2XwsEjJqTPFqn/rOf24l\ngzTwyCvFo1dJPxPRu+M2QQXWduWLgUXKC/O8vUkvSB+UeHWfum5VtU8Olm0zNZN5\n3YSJTlvtW01b3WflY2wcMADl1F5gzO+p8S/0P85aMwNzFoYZ8oI9Btg/x6vr24f0\nl8E84dPJ/xAh5aImlYGOBx4xxYSpspQD1NxzFGTY+L1+K+ZaEmJdpLTSmAL5aSTU\nWhJ3LoWvhGE38dKcPrYnO0MIaIokyop/MX+FQV7OhgXLg3k6QBepwik9t2ZCW4a1\nMsB1/vxB1splDxjz0Wt9/rOWtlCdxufbB9itHcqaYwKBgQDz9KfNxMZdjhGAz7Eo\np56/+JhiyDGV0xeTnppPV8eDJcsE4K4uZ2g+ENZBZqilVOJ6ZUokfy35sxeMINNU\nodT31Au2pbSc51sM6tG4x+7Zq77IseNBoz6i5E4BmH3yocR4bhFW+VjkuT5nbCrR\nyYNbyKl3O9svoo9MtclmrTZ6FwKBgQC4sqLi//Wv1YfiBEI4x8FySsCyd7wbi2ub\nUNctH1LuKfYs8ftvDGFXoqfVN14fL42+oSe7zcCAnLoeBq6+Xil3OcU9SKj2AF5+\nUA2nFh5Cp/5Oy2HVo9jMJq0SLkq3uTLhhoK7nh1HR8/ySk5e/Bgsynby31loiPTM\nFRZ33xUT1wKBgCuZwVfsYEueNZChDhenBZ9nzcxx3TLa3G7cvNWLabQN0CAaC+dt\nQds95R0hPOj3p9aaPcsSE+R51q6Lu1l35vk4I7PMH+CfFM3VNhBhY1Xx9RIngOdd\nRreUOEDewTvZH6Uqftzdib6vQPD5CxodeuWvkmImB4GW+yeJoepA8OiXAoGBAJ3A\nti98v7Q3haAeUxF8TFX6Uywb6Kb+JiK6oY8AuiyB2qn45PCselgHraIHcHTigQbZ\nTr6xtkIjmzQaMRaYBoK27Siy7t1u40avyXl1AtOb+TIDtMRs9bcpMZndwCO8IyFN\nakwleIxxYcNohBfM6jkXlRFMg2kt0SieuloYSEBxAoGAJW9ZzKMyVnWI7XY1fiFk\nteA1wa3Epntud/CRVohCs5xBnCOll4APFr/enQGAzo+JlvHdlXNoqFFQy2PI6vr+\ncBeqGXpnBC7v+SctOnSaZeSb02733Q3p0vbmPjxaF8K2/JWTOeZ0QkfuUCMYGiLt\naN9oqTrj6Eg3dQz7ruVO+5Y=\n
-----END PRIVATE KEY-----`;
  const SCOPES = ["https://www.googleapis.com/auth/spreadsheets"];
  const SPREADSHEET_ID = "19ZXyqArLAH1wjdbcQSBrreSb2jMveCF5h_AxctqXXLU";
  const API_URL = "https://sheets.googleapis.com/v4/spreadsheets/";

  async function authenticate() {
    const now = Math.floor(Date.now() / 1000);
    const jwtHeader = { alg: "RS256", typ: "JWT" };
    const jwtClaimSet = {
      iss: SERVICE_ACCOUNT_EMAIL,
      scope: SCOPES.join(" "),
      aud: "https://oauth2.googleapis.com/token",
      exp: now + 3600,
      iat: now,
    };

    const jwt = KJUR.jws.JWS.sign("RS256", jwtHeader, jwtClaimSet, PRIVATE_KEY);

    const response = await fetch("https://oauth2.googleapis.com/token", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams({
        grant_type: "urn:ietf:params:oauth:grant-type:jwt-bearer",
        assertion: jwt,
      }),
    });

    const data = await response.json();
    return data.access_token;
  }

  const appendData = async (accessToken, values) => {
    const response = await fetch(
      `${API_URL}${SPREADSHEET_ID}/values/Sheet1!A1:append?valueInputOption=RAW`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ values }),
      }
    );
    return response.json();
  };

  async function submitForm(e) {
    e.preventDefault();
    const accessToken = await authenticate();
    let values = [[firstName, lastName, contact, email, company, type]];

    const result = await appendData(accessToken, values);
  }

  return (
    <div
      className="partner_form_img h-[1000px] my-14"
      style={{
        borderRadius: "30px 30px 0px 0px",
      }}
    >
      <div className=" h-full">
        <div className="lg:w-[60%] w-full h-full  flex justify-center flex-col items-center">
          <div className=" lg:w-[50%] w-[70%] ">
            <div className="pb-8">
              <h3 className="partner_form_h  ">Let’s connect</h3>
              <p className="fSans partner_form_p">
                Reach out and one of our associates will be in contact with you
                shortly
              </p>
            </div>
            <form className="fSans flex flex-col gap-4" onSubmit={submitForm}>
              <div className="flex justify-between lg:flex-row  flex-col gap-5">
                <input
                  required={true}
                  value={formData.firstName}
                  onChange={handleInputChange}
                  name="firstName"
                  className="partner_form_input"
                  type="text"
                  placeholder="First name"
                ></input>
                <input
                  required={true}
                  onChange={handleInputChange}
                  name="lastName"
                  value={formData.lastName}
                  className="partner_form_input"
                  type="text"
                  placeholder="Last name"
                ></input>
              </div>
              <div className="flex justify-between lg:flex-row  flex-col gap-5">
                <input
                  required={true}
                  onChange={handleInputChange}
                  name="contact"
                  value={formData.contact}
                  className="partner_form_input"
                  type="number"
                  placeholder="Phone number"
                ></input>
                <input
                  required={true}
                  onChange={handleInputChange}
                  name="email"
                  value={formData.email}
                  className="partner_form_input"
                  type="email"
                  placeholder="Email"
                ></input>
              </div>
              <input
                required={true}
                onChange={handleInputChange}
                name="company"
                value={formData.company}
                className="partner_form_input"
                type="text"
                placeholder="Company"
              ></input>
              {/* <label for="sponsor">Sponsor type:</label> */}
              <select
                required={true}
                value={formData.type}
                className="partner_form_input"
                onChange={handleInputChange}
                name="type"
                id="sponsor"
              >
                <option value="silver" className="grey_color">
                  Silver
                </option>
                <option value="gold" className="grey_color">
                  Gold
                </option>
                <option value="premium" className="grey_color">
                  Premium
                </option>
              </select>
              <div>
                <button type="submit" className="fSans partner_form_submit">
                  Submit
                </button>
              </div>
            </form>
            <div className="my-8">
              <h3 className="partner_form_footer_h">Have a question?</h3>
              <p className="fSans partner_form_p">
                Contact info@teammj.co or call +92 3002773182
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartnerForm;
