import React from "react";
import { Link } from "react-router-dom";
import "../styles/header.css"; // Ensure Header.css contains the relevant styles

const Header = () => {
  return (
    <header className="header">
      {/* Logo Section */}
      <div className="logo">
        <Link to="/">
          <img src="/assets/logo.png" alt="Logo" />
        </Link>
        <span className="logo_name">TEAM MJ</span>
      </div>

      {/* Navigation Links */}
      <nav className="headerTabs">
        <ul className="headerTabGroup">
          <li>
            <Link to="/" className="headerTabText">
              Home
            </Link>
          </li>
          <li>
            <Link to="/about" className="headerTabText">
              About Us
            </Link>
          </li>
          <li>
            <Link to="/events" className="headerTabText">
              Events
            </Link>
          </li>
          <li>
            <Link to="/register" className="headerTabText">
              Register
            </Link>
          </li>
        </ul>
      </nav>

      {/* Register Button */}
      <div className="register">
        <Link to="/register">Register Now</Link>
      </div>
    </header>
  );
};

export default Header;
